// import displayHelper from "@/util/display_helper"
// import datetimeHelper from "@/util/datetime_helper"
import model from "@/model";

/*
{
    createdAt: 1625643032598,
    access: 'admin',
    displayName: 'test4',
    email: 'test4@gmail.com',
    mobile: null,
    clubId: 'd06a80a2-d96f-11eb-9e41-7dccacccbd86',
    clubAdmin: true,
    "squads": [
      {
        "squadId": "dbad4e80-0929-11ec-b1c3-1faeba18a6ac",
        "squadName": "Gold Squad",
        "clubName": "united swimming club"
      }
    ],
  }
 */
export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
    if (object.detail && object.detail.managedSwimmers) {
      object.detail.managedSwimmers = object.detail.managedSwimmers.map((swimmer) => model.memberResponse(swimmer));
    }
  } else {
    object = {
      token: '',
      detail: {
        access: '',
        accesslevel: 0,
        displayName: '',
        email: '',
        clubId: '',
        clubAdmin: false,
        squads: [],
        coach: false,
        subRegion: [],
        managedSwimmers: []
      },
      join: false, // **flag for squad join only
    };
  }
  object.logout = function logout() {
    object.token = '';
    return object;
  }
  object.getToken = function getToken() {
    return object.token ? object.token : '';
  }
  object.isValid = function isValid() {
    return this.getToken().length > 0;
  }
  object.isAuthenticated = function isAuthenticated() {
    return object.isValid();
  }
  object.getDetail = function getDetail() {
    return object.detail ? object.detail : {};
  }
  object.getEmail = function getEmail() {
    return object.getDetail().email ? object.getDetail().email : '';
  }
  // access
  object.getAccess = function getAccess() {
    return object.getDetail().access ? object.getDetail().access : '';
  }
  object.getAccessLevel = function getAccessLevel() {
    return object.getDetail().accesslevel ? object.getDetail().accesslevel : 0;
  }
  object.isAdmin = function isAdmin() {
    return this.getAccess() === 'admin';
  }
  object.isMyTogsUser = function isMyTogsUser() {
    return this.getAccess() === 'mytogsUser';
  }
  object.isInternalUser = function isInternalUser() {
    return this.getEmail() === 'aaron@pushstack.io' ||
      this.getEmail() === 'sam@pushstack.io';
  }
  object.isSuperUser = function isSuperUser() {
    return this.getAccessLevel() == 900;
  }
  // access end
  object.getDisplayName = function getDisplayName() {
    return object.getDetail().displayName ? object.getDetail().displayName : '';
  }
  object.isClubAdmin = function isClubAdmin() {
    return object.getDetail().clubAdmin ? object.getDetail().clubAdmin : false;
  }
  object.getClubId = function getClubId() {
    return object.getDetail().clubId ? object.getDetail().clubId : '';
  }
  object.hasClub = function hasClub() {
    return object.getClubId().length > 0;
  }
  object.getSquads = function getSquads() {
    return object.getDetail().squads ?? [];
  }
  object.getCoachSquads = function getCoachSquads() {
    return object.getSquads().filter((squad) => squad.coach);
  }
  object.getSquadsExcludeCoached = function getSquadsExcludeCoached() {
    return object.getSquads().filter((squad) => !squad.coach);
  }
  object.isSquadCoach = function isSquadCoach(squadId) {
    return object.getSquads().filter((squad) => squad.squadId === squadId && squad.coach).length > 0;
  }
  object.isSquadMember = function isSquadMember(squadMemberId) {
    return object.getSquads().filter((squad) => squad.squadMemberId === squadMemberId).length > 0;
  }
  object.getManagedSwimmers = function getManagedSwimmers() {
    return object.getDetail().managedSwimmers ?? [];
  }
  object.hasSquads = function hasSquads() {
    return object.getSquads().length > 0;
  }
  object.isCoach = function isCoach() {
    return object.getDetail().coach ? object.getDetail().coach : false;
  }
  object.getRegion = function getRegion() {
    return object.getDetail().subRegion ? object.getDetail().subRegion : [];
  }
  object.hasRegions = function hasRegions() {
    return object.getRegion().length > 0;
  }
  object.hasAllRegions = function hasAllRegions() {
    return object.getRegion().findIndex((region) => model.regionResponse(region).isTopicNews()) >= 0;
  }
  object.formattedRole = function formattedRole() {
    return (this.isAdmin() || this.isClubAdmin()) ? 'Administrator' : this.isCoach() ? 'Club Coach' : 'Member';
  }
  object.hasMemberControl = function hasMemberControl() {
    return (this.isAdmin() || this.isClubAdmin() || this.isCoach());
  }
  object.hasManagedSwimmers = function hasManagedSwimmers() {
    return object.getManagedSwimmers().length > 0;
  }
  object.isJoin = function isJoin() {
    return object.join ? object.join : false;
  }
  return object;
}

