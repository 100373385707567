<template>
  <div class="container-fluid nav_bar">
    <!-- desktop mode -->
    <div class="desktop d-flex flex-row justify-content-between">
      <div class="selected nav_button-selected" style="margin-top: 2.8rem; font-size: 2em">
        Hi! {{ storeAuth.getDisplayName() }}
      </div>
      <div class="flex-grow-1 d-flex flex-column justify-content-between">
        <!-- version -->
        <div class="flex-grow-1 d-flex flex-row justify-content-end pt-1 text-secondary" style="font-size: 10px">
          {{ appVersion }}
        </div>
        <!-- account -->
        <div class="flex-grow-1 d-flex flex-row justify-content-between pl-3 pr-3">
          <div></div>
          <!-- main navigation -->
          <div v-for="option in mNavigationOption.options" v-bind:key="option.value">
            <div class="selected nav_button-selected" style="margin-top: 1rem; margin-right: 2rem">

            </div>
          </div>

          <div class="selected nav_button-selected clickable"
               style="margin-top: 2rem; margin-right: 2rem"
          v-on:click.prevent="onClickLogout">
            Logout
          </div>

        </div>
      </div>
    </div>
    <!-- mobile mode -->
    <div class="mobile d-flex flex-row justify-content-between">
      <div class="brand">
        <img alt="mytogs logo" src="../assets/images/logo-mytogs-white.png">
      </div>
      <!-- selected option -->
      <label class="elected nav_button-selected mt-4">{{ selectedPage.text }}</label>

      <!-- menu click-->
      <b-icon class="my_menu_list_trigger open mt-3 mr-1 clickable"
              icon="list"
              v-on:click.prevent="onClickMenuList">
      </b-icon>
    </div>
    <!-- mobile menu nav -->
    <div id="nav_bar_mobile" v-if="windowWidth < 768" class="mobile nav_bar_mobile">
      <div style="width: 100%; text-align: right">
        <b-icon class="my_menu_list_trigger close mt-3 mr-3 clickable"
                icon="x"
                v-on:click.prevent="onClickMenuList">
        </b-icon>
        <div class="" style="margin-top: 6rem; margin-right: 2rem;">
          <div class="side_button clickable mt-2 pt-2 pb-2"
               :class="{'side_button-selected': mSideOption.selected === option.value}"
               v-on:click.prevent="onClickSideOption(option)"
               v-for="option in mSideOption.options" v-bind:key="option.value">
            {{option.text}}
          </div>
        </div>

        <!-- logout -->
        <div class="" style="margin-top: 6rem; margin-right: 2rem;">
          <div class="side_button clickable mt-2 pt-2 pb-2"
               v-on:click.prevent="onClickLogout()">
            Logout
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UpdateBanner from "@/views/content/update_banner"
import UpdateResult from "@/views/content/update_result"
import UpdateEvent from "@/views/content/update_event"
import UpdatePyschSheet from "@/views/content/update_pysch_sheet"
import BroadcastMessage from "@/views/content/broadcast_message"
import Report from "@/views/content/report"
import ResetPassword from "@/views/content/reset_password"
import SuperUser from "@/views/content/super_user"
import PremiumUsers from "@/views/content/premium_users"
import SyncUserRecords from '@/views/content/sync_user_records.vue'
// mysquad
import MyClub from "@/views/content/my_club"
import SetupClub from "@/views/content/setup_club"
import ClubReports from "@/views/content/club_reports"
import TrainingSets from "@/views/content/training_sets"
import MySquads from "@/views/content/my_squads"
import AppBloc from '@/bloc/app_bloc'
import logger from "@/util/logger"

const LOG_TAG = 'nav_bar';

export default {
  name: LOG_TAG,
  props: {
    navOptions: {
      type: Array,
      required: true,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return [];
      }
    },
    navHelp: {
      type: Object,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return {
          text: 'Help',
          value: 'help'
        };
      }
    },
    navAccount: {
      type: Object,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return {
          text: 'Account',
          value: 'account'
        };
      }
    }
  },
  data() {
    return {
      mNavigationOption: {
        options: [],
        selected: '',
      },
      mOpenMobileMenu: false,

      mSideOption: {
        options: [],
      }
    };
  },
  watch: {
    windowWidth: function (value) {
      const vm = this;
      if (value >= 768) {
        vm.mOpenMobileMenu = false;
      } else {
        const defaultOptions = vm.mNavigationOption.options[0];
        if (defaultOptions && vm.mNavigationOption.selected !== defaultOptions.value) {
          // vm.onClickOption(defaultOptions.value);
        }
      }
    },
    selectedPage: function (value) {
      const vm = this;
      logger.d(LOG_TAG, 'watch', 'selectedPage', value.value);
      const optionChange = vm.navOptions.find((option) => value.value ? value.value.includes(option.value) : false);
      if (optionChange && optionChange !=vm.mNavigationOption.selected) {
        vm.mNavigationOption.selected = optionChange.value;
      }
    },
  },
  computed: {
    isMySquad() {
      return process.env.VUE_APP_TARGET === 'mysquad';
    },
    appVersion() {
      return this.$store.state.appVersion;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    selectedPage() {
      return this.$store.state.selectedPage;
    },
    storeState() {
      return this.$store.state;
    },
    isSuperUser() {
      return this.storeState.firebaseUser.superUser;
    },
    storeAuth() {
      return this.storeState.auth;
    },
    isAdmin() {
      return this.storeAuth.isAdmin() || this.storeAuth.isClubAdmin();
    },
    isCoach() {
      return this.storeAuth.isCoach();
    },
    storeSquadsExcludeCoached() {
      return this.storeAuth.getSquadsExcludeCoached();
    },
  },
  mounted() {
    const vm = this;
    // logger.d(LOG_TAG, 'mounted', vm.$route.name);
    const auth = vm.storeAuth;
    if (vm.isMySquad) {
      const options = [];
      logger.d(LOG_TAG, 'mounted', vm.isAdmin, vm.isCoach, vm.storeAuth.hasSquads());

      if (vm.storeAuth.isAdmin()) {
        options.push({text: SetupClub.display, value: SetupClub.route});
      }
      if (vm.isAdmin || vm.isCoach) {
        options.push({text: MyClub.display, value: MyClub.route});
        if (vm.storeAuth.hasClub()) {
          options.push({text: TrainingSets.display, value: TrainingSets.route});
          options.push({text: ClubReports.display, value: ClubReports.route});
        }
      }
      // generic options (only displays non coach squads)
      if (vm.storeSquadsExcludeCoached.length > 0) {
        options.push({text: MySquads.display, value: MySquads.route});
      }

      vm.mSideOption.options = options;
    } else {
      if (auth.isAdmin()) {
        vm.mSideOption.options = [
          {text: UpdateResult.display, value: UpdateResult.route},
          {text: UpdateEvent.display, value: UpdateEvent.route},
          {text: UpdatePyschSheet.display, value: UpdatePyschSheet.route},
          {text: UpdateBanner.display, value: UpdateBanner.route},
        ];
        if (vm.storeAuth.hasRegions()) {
          vm.mSideOption.options.push({text: BroadcastMessage.display, value: BroadcastMessage.route});
        }
        vm.mSideOption.options.push({text: Report.display, value: Report.route});
        vm.mSideOption.options.push({text: ResetPassword.display, value: ResetPassword.route});
        if (vm.isSuperUser) {
          vm.mSideOption.options.push({text: SuperUser.display, value: SuperUser.route});
          vm.mSideOption.options.push({text: PremiumUsers.display, value: PremiumUsers.route});
          vm.mSideOption.options.push({text: SyncUserRecords.display, value: SyncUserRecords.route});
        }
      } else {
        vm.mSideOption.options = [
          {text: UpdateResult.display, value: UpdateResult.route},
          {text: UpdateEvent.display, value: UpdateEvent.route},
          {text: UpdatePyschSheet.display, value: UpdatePyschSheet.route},
        ];
      }
    }


    // nav
    if (vm.navOptions.length > 0) {
      vm.navOptions.forEach((option) => {
        vm.mNavigationOption.options.push(option);
      });
      const defaultTab = vm.navOptions.find((option) => vm.$route.name.includes(option.value));
      logger.d(LOG_TAG, 'mounted', vm.$route.name, defaultTab);
      vm.mNavigationOption.selected = defaultTab ? defaultTab.value : null;
    }
  },
  methods: {
    onClickLogout() {
      window.firebase.auth()
        .signOut()
        .then(() => {
          AppBloc.logout();
        })
        .catch(() => {
          AppBloc.logout();
        });
    },
    onClickOption(value) {
      const vm = this;
      logger.d(LOG_TAG, 'onClickOption', value);
      vm.$store.commit('setSelectedPage', {text: '', value: ''});

      vm.mNavigationOption.selected = value;
      vm.$emit('click', value);
    },
    onClickHelp() {
      // const vm = this;
      // logger.d(LOG_TAG, 'onClickHelp');
      // vm.$store.commit('setSelectedPage', {text: '', value: ''});

      // vm.mNavigationOption.selected = vm.navHelp.value;
      // vm.$emit('click', vm.navHelp.value);
    },
    onClickAccount() {
      // const vm = this;
      // logger.d(LOG_TAG, 'onClickAccount');
      // vm.$store.commit('setSelectedPage', {text: '', value: ''});

      // vm.mNavigationOption.selected = vm.navAccount.value;
      // vm.$emit('click', vm.navAccount.value);
    },
    onClickMenuList() {
      const vm = this;
      //logger.d(LOG_TAG, 'onClickMenuList');
      vm.$emit('click', 'open_menu');
      vm.mOpenMobileMenu = !vm.mOpenMobileMenu;
      if (vm.mOpenMobileMenu) {
        document.getElementById("nav_bar_mobile").style.width = '75%';
      } else {
        document.getElementById("nav_bar_mobile").style.width = '0';
      }
    },
    onClickSideOption(option) {
      //
      const vm = this;
      logger.d(LOG_TAG, 'onClickSideOption', vm.$route.name, option.value);
      vm.onClickMenuList();
      if (vm.$route.name !== option.value) {
        vm.$store.commit('setSelectedPage', option);
        vm.$router.push({name: option.value});
      }
    }
  }
}
</script>
<style scoped>
.nav_bar {
  height: 6rem;
  border-bottom: 0.1rem solid lightgrey;
  background-color: var(--background-blue);
}
.nav_bar .desktop {
  display: flex !important;
  height: 100%;
}
.nav_bar .mobile {
  display: none !important;
  height: 0;
}
.nav_bar .mobile label {
  font-weight: bold;
  text-transform: uppercase;
}
.brand {
  max-width: 6rem;
  height: 100%;
}
.brand img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.my_account {
  width: 2.5em;
  height: 2.5em;
  color: lightgrey;
}
.my_menu_list_trigger {
  width: 2.5em;
  height: 2.5em;
  opacity: 1;
}
.my_menu_list_trigger.open {
  color: var(--primary-text);
}
.my_menu_list_trigger.close {
  color: var(--primary-text);
}
.nav_button {
  display: block;
  text-align: center;
  padding: 12px 16px;
  color: darkgrey;
  text-decoration: none;
}
.nav_button-selected {
  color: var(--primary-highlight);
  font-weight: bold;
}
.nav_button:hover {
  color: dimgray;
}
.nav_button-selected-underline {
  height: 2px;
  background-color: var(--primary-highlight);
}
.nav_bar_mobile {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 6;
  top: 0;
  right: 0;
  background-color: #444444;
  overflow-x: hidden;
  transition: .3s;
}

.side_button {
  display: block;
  width: 100%;
  color: white;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-top-right-radius: 6rem;
  border-bottom-right-radius: 6rem;
}
.side_button:hover {
  color: var(--primary);
  background-color: var(--primary-highlight-background);
}
.side_button-selected {
  color: var(--primary-highlight);
  background-color: var(--primary-highlight-background);
}

@media screen and (max-width: 767px) {
  .nav_bar {
    height: 4rem;
  }
  .nav_bar .desktop {
    display: none !important;
    height: 0;
  }
  .nav_bar .mobile {
    display: flex !important;
    height: 100%;
  }
  .side_button {
    font-size: 1.3em;
  }
  .brand {
    max-width: 3rem;
    height: 100%;
  }
}
</style>
