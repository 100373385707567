<template>
  <content-layout>

    <div slot="content_container">
      <div v-if="mPageState.isEnabled()" class="row mt-2 mb-5">
        <div class="col-1 desktop"></div>

        <!-- content section-->
        <section
            class="col-9"
            :class="{
            'col-12': windowWidth <= 768
            }"
            style="text-align: left; max-width: 768px">

          <!-- search mytogs user -->
          <form class="form-group" v-on:submit.prevent="onSearchUser()">
            <label class="my-text-primary my-input-label">Search User:</label>
            <input type="email"
                   class="form-control my-box-decoration"
                   placeholder="Enter email"
                   v-model="mSearchUser.email">
            <div class="d-flex flex-row justify-content-end mt-4">
              <button type="button"
                      class="btn btn-outline-primary my-box-decoration ml-2"
                      style="width: 6rem"
                      v-bind:disabled="!mSearchUser.email"
                      v-on:click.stop="onSearchUser()">
                {{ 'Search' }}
              </button>
            </div>
            <div v-if="mSearchUser.user.isValid()" class="my-text-primary" style="font-size: 0.85em">
              <div><span class="font-weight-bold">Name: </span><span>{{mSearchUser.user.getName()}}</span></div>
              <div><span class="font-weight-bold">Email: </span><span>{{mSearchUser.user.getEmail()}}</span></div>
              <div><span class="font-weight-bold">User Id: </span><span>{{mSearchUser.user.getId()}}</span></div>
              <div><span class="font-weight-bold">Created@: </span><span>{{mSearchUser.profile.formattedCreateAt()}}</span></div>
              <!-- premium option-->
              <div><span class="font-weight-bold">Premium User: </span><span>{{mSearchUser.profile.formattedPremiumState()}}</span></div>
              <div class="d-flex flex-row justify-content-start mt-2">
                <b-form-select id="premium-select" v-model="mPremiumOption.selected" :options="mPremiumOption.options"></b-form-select>
                <button type="button"
                        class="btn btn-outline-primary my-box-decoration ml-2"
                        style="width: 6rem"
                        v-bind:disabled="!isPremiumStateChanged"
                        v-on:click.stop="onApplyPremiumStateChange()">
                  Apply
                </button>
              </div>
              <!-- premium addons-->
              <div v-if="mPremiumOption.selected">
                <div class="mt-2 mb-1"><span class="font-weight-bold">Addons: </span></div>
                <div
                    v-for="(addon, index) in mAddonOption.options" v-bind:key="index"
                    class="d-flex flex-row justify-content-between align-items-center ml-2 mr-2">
                  <div> {{addon.text}}</div>
                  <div>
                    <input type="radio"
                           :name="addon.key"
                           :id="addon.key"
                           :value=true
                           v-model="addon.value"
                           v-on:change="onNotifyAddonChanged(addon)">
                    <label class="ml-1" style="font-size: .9em" :for="addon.key">Enable</label>

                    <input class="ml-3" type="radio"
                           :name="addon.key+'disable'"
                           :id="addon.key+'disable'"
                           :value=false
                           v-model="addon.value"
                           v-on:change="onNotifyAddonChanged(addon)">
                    <label class="ml-1" style="font-size: .9em" :for="addon.key+'disable'">Disable</label>
                  </div>
                </div>
              </div>

              <div class="mt-4"/>

              <!-- last update -->
              <div><span class="font-weight-bold">Last update@: </span><span>{{mSearchUser.profile.formattedUpdatedAt()}}</span></div>
              <div><span class="font-weight-bold">Last sign in@: </span><span>{{mSearchUser.profile.formattedSignInAt()}}</span></div>

              <div v-if="mSearchUser.actions.length > 0">
                <div><span class="font-weight-bold">Last actions: </span></div>
                <div v-for="action in mSearchUser.actions" v-bind:key="action.getTimestamp()">
                  <span class="ml-2" style="font-size: 0.75em"><span class="font-weight-bold">[{{action.formattedTimestamp()}}]:</span> {{action.getAction()}} </span>
                </div>
              </div>

              <div class="d-flex flex-row justify-content-start mt-2">
                <b-form-select id="command-select" v-model="mCommand.selected" :options="mCommand.options"></b-form-select>
                <button type="button"
                        class="btn btn-outline-primary my-box-decoration ml-2"
                        style="width: 6rem"
                        v-bind:disabled="mCommand.selected === 'select'"
                        v-on:click.stop="onApplyCommand()">
                  Apply
                </button>
              </div>
            </div>
          </form>
          <separator-line></separator-line>
          <!-- force update app version -->
          <form class="form-group mt-3" v-on:submit.prevent="onUpdateAppVersion()">
            <label class="my-text-primary my-input-label">Notify App update:</label>
            <input type="text"
                   class="form-control my-box-decoration"
                   placeholder="Enter latest App version"
                   v-model="mAppVersion.update">
            <div class="d-flex flex-row justify-content-end mt-4">
              <button type="button"
                      class="btn btn-outline-primary my-box-decoration ml-2"
                      style="width: 6rem"
                      v-bind:disabled="!isValidAppUpdate"
                      v-on:click.stop="onUpdateAppVersion()">
                Update
              </button>
            </div>
          </form>
          <separator-line></separator-line>

          <!-- global app addons  -->
          <form class="form-group mt-3 my-text-primary" v-on:submit.prevent="onUpdateAppVersion()">
            <label class="my-text-primary my-input-label">App add-ons update:</label>

            <div class="mt-2 mb-1"><span class="font-weight-bold">Addons: </span></div>
            <div
                v-for="(addon, index) in mGlobalAddonOption.options" v-bind:key="index"
                class="d-flex flex-row justify-content-between align-items-center ml-2 mr-2"
                :class="{'disabled': addon.disabled}">
              <div> {{addon.text}}</div>
              <div>
                <input type="radio"
                       :name="addon.key"
                       :id="addon.key"
                       :value=true
                       :disabled="addon.disabled"
                       v-model="addon.value"
                       v-on:change="onNotifyGlobalAddonChanged(addon)">
                <label class="ml-1" style="font-size: .9em" :for="addon.key">Enable</label>

                <input class="ml-3" type="radio"
                       :name="addon.key+'disable'"
                       :id="addon.key+'disable'"
                       :value=false
                       :disabled="addon.disabled"
                       v-model="addon.value"
                       v-on:change="onNotifyGlobalAddonChanged(addon)">
                <label class="ml-1" style="font-size: .9em" :for="addon.key+'disable'">Disable</label>
              </div>
            </div>

            <div class="d-flex flex-row justify-content-end mt-4">
              <button type="button"
                      class="btn btn-outline-primary my-box-decoration ml-2"
                      style="width: 6rem"
                      v-bind:disabled="!isGlobalAppAddonsChanged"
                      v-on:click.stop="onUpdateAppGlobalAddons()">
                Update
              </button>
            </div>

          </form>
          <separator-line></separator-line>

          <!-- in app notify -->
          <form class="form-group mt-3">
            <label class="my-text-primary my-input-label">In App notification:</label>
            <input type="text"
                   class="form-control my-box-decoration"
                   placeholder="Enter description"
                   v-model="mInAppNotification.textUpdate">
            <input type="url"
                   class="form-control my-box-decoration mt-1"
                   placeholder="Enter link (optional)"
                   v-model="mInAppNotification.linkUpdate">
            <div class="d-flex flex-row justify-content-end mt-4">
              <button type="button"
                      class="btn btn-outline-primary my-box-decoration ml-2"
                      style="width: 6rem"
                      v-bind:disabled="!(mInAppNotification.text || mInAppNotification.link)"
                      v-on:click.stop="onUpdateInAppNotification(true)">
                Clear
              </button>
              <button type="button"
                      class="btn btn-outline-primary my-box-decoration ml-2"
                      style="width: 6rem"
                      v-bind:disabled="!isValidInAppNotification"
                      v-on:click.stop="onUpdateInAppNotification()">
                Update
              </button>
            </div>
          </form>



        </section>

        <div class="col-1 desktop"></div>
      </div>
      <div v-else-if="mPageState.isDisabled()">
        <label class="my-text-primary my-input-label" style="margin-top: 4rem">You do not have permission to this page.</label>
      </div>
      <div v-else>
        <loader :embedded="true" style="width: 100%; margin-top: 4rem"></loader>
      </div>
    </div>
  </content-layout>
</template>

<script>
import ContentLayout from "@/components/content_layout"
import SeparatorLine from "@/components/separator_line"
import Loader from "@/components/loader"
import ProfileAddons from "@/model/app_user_profile_addons"
import firebaseRdb from "@/firebase/realtime_database"
import mytogsBloc from "@/bloc/mytogs_bloc"
import appBloc from "@/bloc/app_bloc";
import model from "@/model"
import logger from "@/util/logger"

const LOG_TAG = 'super_user';

const PAGE_STATE_INITIALIZING = 'initializing';
const PAGE_STATE_ENABLED = 'enabled';
const PAGE_STATE_DISABLED = 'disabled';

export default {
  name: LOG_TAG,
  display: 'Super User',
  route: `dashboard.${LOG_TAG}`,
  components: {
    ContentLayout,
    SeparatorLine,
    Loader
  },
  data() {
    return {

      mPageState: {
        options: [
          PAGE_STATE_INITIALIZING,
          PAGE_STATE_ENABLED,
          PAGE_STATE_DISABLED
        ],
        selected: PAGE_STATE_INITIALIZING,
        setInitializing() {
          this.selected = this.options[0];
        },
        setEnabled() {
          this.selected = this.options[1];
        },
        setDisabled() {
          this.selected = this.options[2];
        },
        isEnabled() {
          return this.selected === this.options[1];
        },
        isDisabled() {
          return this.selected === this.options[2];
        }
      },
      mAppVersion: {
        current: '',
        update: '',
        setData(value) {
          this.current = this.update = value;
        },
        isUpdate() {
          const updateSplits = this.update.split('.');
          const currentSplits = this.current.split('.');
          if (updateSplits.length == 4 && currentSplits.length == 4) {
            return parseInt(updateSplits[3]) > parseInt(currentSplits[3]);
          }
          return false;
        }
      },
      mInAppNotification: {
        text: '',
        textUpdate: '',
        link: '',
        linkUpdate: '',
        setData(text, link) {
          this.text = this.textUpdate = text;
          this.link = this.linkUpdate = link;
        },
        isUpdate() {
          return this.textUpdate && (this.text !== this.textUpdate || this.link !== this.linkUpdate);
        }
      },
      mSearchUser: {
        email: '',
        user: model.memberResponse(),
        profile: model.appUserProfile(),
        actions: [],
        reset() {
          this.email = '';
          this.user = model.memberResponse();
          this.profile = model.appUserProfile();
          this.actions = [];
        }
      },
      mCommand: {
        selected: 'select',
        options: [
          {text: 'Select action', value: 'select'},
          {text: 'Logout', value: 'logout'}
        ],
        isLogout() {
          return this.selected === 'logout';
        },
        reset() {
          this.selected = 'select';
        }
      },
      mPremiumOption: {
        selected: false,
        options: [
          {text: 'Yes', value: true},
          {text: 'No', value: false}
        ],
      },
      mAddonOption: {
        current: ProfileAddons(),
        options: [],
        updated: false,
        reset(value) {
          this.current = value;
          this.options = [
            {text: '10 Managed Swimmers', key: 'swimmer_10', value: value.isSupportSwimmer10()},
            {text: 'Swimmer Performance', key: 'swimmer_performance', value: value.isSupportSwimmerPerformance()},
            {text: 'Link Fastlane', key: 'link_fastlane', value: value.isSupportLinkFastlane()},
            {text: 'Meet Result', key: 'meet_result', value: value.isSupportMeetResult()},
            {text: 'XLR8 Points', key: 'xlr8_points', value: value.isSupportXlr8Points()},
            {text: 'Pysch Sheet', key: 'pysch_sheet', value: value.isSupportPyschSheet()},
          ];
          this.updated = false;
          //logger.d(LOG_TAG, 'mAddonOption', value);
        },
        defaultOption(value) {
          if (value) {
            this.options[0].value = value.isSupportSwimmer10();
            this.options[1].value = value.isSupportSwimmerPerformance();
            this.options[2].value = value.isSupportLinkFastlane();
            this.options[3].value = value.isSupportMeetResult();
            this.options[4].value = value.isSupportXlr8Points();
            this.options[5].value = value.isSupportPyschSheet();
          } else {
            this.options[0].value = true;
            this.options[1].value = true;
            this.options[2].value = false;
            this.options[3].value = false;
            this.options[4].value = false;
            this.options[5].value = false;
          }

          this.updated = false;
        },
        emptyOption() {
          this.options[0].value = false;
          this.options[1].value = false;
          this.options[2].value = false;
          this.options[3].value = false;
          this.options[4].value = false;
          this.options[5].value = false;
          this.updated = false;
        },
        validate() {
          this.updated =
              this.options[0].value !== this.current.isSupportSwimmer10() ||
              this.options[1].value !== this.current.isSupportSwimmerPerformance() ||
              this.options[2].value !== this.current.isSupportLinkFastlane() ||
              this.options[3].value !== this.current.isSupportMeetResult() ||
              this.options[4].value !== this.current.isSupportXlr8Points() ||
              this.options[5].value !== this.current.isSupportPyschSheet();
        },
        toJson() {
          let json = {};
          this.options.forEach((option) => {
            json[option.key] = option.value;
          });
          return json;
        }
      },
      mGlobalAddonOption: {
        current: ProfileAddons(),
        options: [],
        updated: false,
        reset(value) {
          this.current = value;
          this.options = [
            {text: '10 Managed Swimmers', key: 'swimmer_10', value: value.isSupportSwimmer10(), disabled: true},
            {text: 'Swimmer Performance', key: 'swimmer_performance', value: value.isSupportSwimmerPerformance()},
            {text: 'Link Fastlane', key: 'link_fastlane', value: value.isSupportLinkFastlane()},
            {text: 'Meet Result', key: 'meet_result', value: value.isSupportMeetResult()},
            {text: 'XLR8 Points', key: 'xlr8_points', value: value.isSupportXlr8Points()},
            {text: 'Pysch Sheet', key: 'pysch_sheet', value: value.isSupportPyschSheet()},
          ];
          this.updated = false;
          //logger.d(LOG_TAG, 'mAddonOption', value);
        },
        defaultOption(value) {
          if (value) {
            this.options[0].value = value.isSupportSwimmer10();
            this.options[1].value = value.isSupportSwimmerPerformance();
            this.options[2].value = value.isSupportLinkFastlane();
            this.options[3].value = value.isSupportMeetResult();
            this.options[4].value = value.isSupportXlr8Points();
            this.options[5].value = value.isSupportPyschSheet();
          } else {
            this.options[0].value = false;
            this.options[1].value = false;
            this.options[2].value = false;
            this.options[3].value = false;
            this.options[4].value = false;
            this.options[5].value = false;
          }

          this.updated = false;
        },
        emptyOption() {
          this.options[0].value = false;
          this.options[1].value = false;
          this.options[2].value = false;
          this.options[3].value = false;
          this.options[4].value = false;
          this.options[5].value = false;
          this.updated = false;
        },
        validate() {
          this.updated =
              this.options[0].value !== this.current.isSupportSwimmer10() ||
              this.options[1].value !== this.current.isSupportSwimmerPerformance() ||
              this.options[2].value !== this.current.isSupportLinkFastlane() ||
              this.options[3].value !== this.current.isSupportMeetResult() ||
              this.options[4].value !== this.current.isSupportXlr8Points() ||
              this.options[5].value !== this.current.isSupportPyschSheet();
        },
        toJson() {
          let json = {};
          this.options.forEach((option) => {
            json[option.key] = option.value;
          });
          return json;
        }
      }
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    storeState() {
      return this.$store.state;
    },
    storeFirebaseUid() {
      return this.storeState.firebaseUser.uid;
    },
    isValidAppUpdate() {
      const vm = this;
      return vm.mAppVersion.isUpdate();
    },
    isValidInAppNotification() {
      const vm = this;
      return vm.mInAppNotification.isUpdate();
    },
    isPremiumStateChanged() {
      const vm = this;
      let hasStateChange = vm.mPremiumOption.selected !== vm.mSearchUser.profile.getPremium();
      let hasAddonChange = vm.mAddonOption.updated;
      logger.d(LOG_TAG, 'isPremiumStateChanged', hasStateChange, hasAddonChange);
      return hasStateChange || hasAddonChange;
    },
    isGlobalAppAddonsChanged() {
      const vm = this;
      let hasChanged = vm.mGlobalAddonOption.updated;
      logger.d(LOG_TAG, 'isGlobalAppAddonsChanged', hasChanged);
      return hasChanged;
    }
  },
  watch: {
    'mPremiumOption.selected': function (value) {
      const vm = this;
      let hasStateChange = vm.mPremiumOption.selected !== vm.mSearchUser.profile.getPremium();
      if (hasStateChange) {
        if (value) {
          vm.mAddonOption.defaultOption();
        } else {
          vm.mAddonOption.emptyOption();
        }
      } else {
        vm.mAddonOption.defaultOption(vm.mAddonOption.current);
      }
    },
  },
  mounted() {
    const vm = this;
    vm.initialize();
  },
  methods: {
    initialize() {
      const vm = this;
      if (vm.storeFirebaseUid) {
        vm.mPageState.setInitializing();
        firebaseRdb.syncUserProfile(vm.storeFirebaseUid,
            (profile) => {
              if (profile.super_user) {
                firebaseRdb.syncAppConfig(
                    (appConfig) => {
                      logger.d(LOG_TAG, 'getAppVersion', appConfig);
                      vm.mAppVersion.setData(appConfig.getVersion());
                      vm.mInAppNotification.setData(appConfig.getDescription(), appConfig.getLinkUrl());

                      // sync addon config
                      firebaseRdb.syncAppAddonsConfig(
                          (addonsConfig) => {
                            logger.d(LOG_TAG, 'syncAppAddonsConfig', addonsConfig);
                            vm.mGlobalAddonOption.reset(addonsConfig);

                            vm.mPageState.selected = vm.mPageState.options[1];
                            vm.mPageState.setEnabled();
                          },
                          (error) => {
                            logger.w(LOG_TAG, 'syncAppAddonsConfig', error);
                            vm.mPageState.setDisabled();
                          }
                      );

                    },
                    (error) => {
                      logger.w(LOG_TAG, 'syncAppConfig', error);
                      vm.mPageState.setDisabled();
                    }
                );
              } else {
                vm.mPageState.setDisabled();
              }
            },
            () => {
              vm.mPageState.setDisabled();
            }
        )
      } else {
        vm.mPageState.setDisabled();
      }

    },
    onUpdateAppVersion() {
      const vm = this;
      vm.$store.commit('setLoading', true);
      firebaseRdb.updateAppVersion(vm.mAppVersion.update,
      () => {
        logger.d(LOG_TAG, 'onUpdateAppVersion');
        vm.mAppVersion.setData(vm.mAppVersion.update);
        vm.$notify({ type: 'success', text: 'version updated successfully.' });
        vm.$store.commit('setLoading', false);
        appBloc.slackAppUpdates(vm.mAppVersion.update);
      }, (error) => {
        logger.w(LOG_TAG, 'onUpdateAppVersion', error);
        vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later.' });
            vm.$store.commit('setLoading', false);
      });
    },
    onUpdateAppGlobalAddons() {
      const vm = this;
      vm.$store.commit('setLoading', true);
      firebaseRdb.updateAppAddonsConfig(vm.mGlobalAddonOption.toJson(),
      (addonsConfig) => {
        logger.d(LOG_TAG, 'onUpdateAppGlobalAddons');

        vm.mGlobalAddonOption.reset(addonsConfig);
        vm.$notify({ type: 'success', text: 'Updated successfully.' });
        vm.$store.commit('setLoading', false);
        appBloc.slackAddonUpdates(vm.mGlobalAddonOption.options);
      }, (error) => {
        logger.w(LOG_TAG, 'onUpdateAppGlobalAddons', error);
        vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later.' });
        vm.$store.commit('setLoading', false);
      });
    },
    onUpdateInAppNotification(clear) {
      const vm = this;
      if (clear) {
        vm.mInAppNotification.textUpdate = '';
        vm.mInAppNotification.linkUpdate = '';
      }
      firebaseRdb.updateInAppNotification(vm.mInAppNotification.textUpdate, vm.mInAppNotification.linkUpdate,
          () => {
            logger.d(LOG_TAG, 'onUpdateInAppNotification');
            vm.mInAppNotification.setData(
              vm.mInAppNotification.textUpdate,
              vm.mInAppNotification.linkUpdate
            );
            vm.$notify({ type: 'success', text: 'Notification updated successfully.' });
          }, (error) => {
            logger.w(LOG_TAG, 'onUpdateInAppNotification', error);
            vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later.' });
          });
    },
    onSearchUser() {
      const vm = this;
      /*if (vm.mSearchUser.user.isValid()) {
        vm.mSearchUser.reset();
        vm.mCommand.reset();
      } else {

      }*/
      vm.$store.commit('setLoading', true);
      mytogsBloc.searchUser(vm.mSearchUser.email,
          (data) => {
            logger.d(LOG_TAG, 'onSearchUser', data.getId());
            firebaseRdb.syncUserProfile(data.getId(),
                (profile, actions) => {
                  vm.mSearchUser.user = data;
                  vm.mSearchUser.profile = profile;
                  vm.mSearchUser.actions = actions;
                  vm.mCommand.reset();
                  vm.mPremiumOption.selected = profile.getPremium();
                  vm.mAddonOption.reset(profile.getAddons());
                  vm.$store.commit('setLoading', false);
                },
                () => {
                  vm.$notify({ type: 'error', text: 'Oops! User not found.' });
                  vm.$store.commit('setLoading', false);
                });

          },
          () => {
            vm.$notify({ type: 'error', text: 'Oops! User not found.' });
            vm.$store.commit('setLoading', false);
          });
    },
    onApplyCommand() {
      const vm = this;
      if (vm.mCommand.isLogout()) {
        firebaseRdb.requestActionLogout(vm.mSearchUser.user.getId(),
        (actions) => {
          vm.mSearchUser.actions = actions;
          vm.$notify({ type: 'success', text: 'updated successfully.' });
        },
        () => {
          vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later.' });
        });
      }
    },
    onApplyPremiumStateChange() {
      const vm = this;
      firebaseRdb.requestUserProfilePremium(vm.mSearchUser.user.getId(), vm.mPremiumOption.selected,
      () => {
        firebaseRdb.updateUserProfileAddon(vm.mSearchUser.user.getId(), vm.mAddonOption.toJson(),
        () => {
          vm.onSearchUser();
          vm.$notify({ type: 'success', text: 'updated successfully.' });
        },
        () => {
          vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later.' });
        });
      },
      () => {
        vm.$notify({ type: 'error', text: 'Oops! Something went wrong, please try again later.' });
      });
    },
    onNotifyAddonChanged(addon) {
      logger.d(LOG_TAG, 'onNotifyAddonChanged', addon);
      const vm = this;
      vm.mAddonOption.validate();
    },
    onNotifyGlobalAddonChanged(addon) {
      logger.d(LOG_TAG, 'onNotifyGlobalAddonChanged', addon);
      const vm = this;
      vm.mGlobalAddonOption.validate();
    },
  }
}
</script>

<style scoped>
.warning {
  color: var(--indicator-red);
}
.label {
  font-weight: bold;
}
.description {
  font-weight: lighter;
  font-size: smaller;
}
.label {
  color: var(--primary-text);
  font-weight: bold;
  font-size: 1em;
}
.desktop {
  display: block;
}
@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
}
</style>
