import 'firebase/database';
import model from "@/model"
import dateTimeHelper from "@/util/datetime_helper";
import logger from "@/util/logger";

const LOG_TAG = 'realtime_database';

const KEY_VERSION = 'version';
const KEY_DESCRIPTION = 'description';
const KEY_LINK_URL = 'link_url';

const PATH_PROFILE = 'profile';
const PATH_PREMIUM = 'premium';
const PATH_ADD_ONS = 'add_ons';

// action log
const PATH_ACTION_LOG = 'action_log';
// key
const KEY_ACTION = 'action';
const KEY_TIMESTAMP = 'timestamp';
// action types
const ACTION_LOGOUT = 'logout';


export default {
  getAppConfigRef() {
    return window.firebaseDatabaseRef
      .ref(process.env.VUE_APP_FIREBASE_DATABASE)
      .child(process.env.VUE_APP_FIREBASE_DATABASE_APP_CONFIG);
  },
  getAppAddonsRef() {
    return window.firebaseDatabaseRef
      .ref(process.env.VUE_APP_FIREBASE_DATABASE)
      .child(process.env.VUE_APP_FIREBASE_DATABASE_APP_ADD_ONS);
  },
  getAppUser() {
    return window.firebaseDatabaseRef.ref('prod/user');
  },
  getAppUserProfileRef(userId) {
    return window.firebaseDatabaseRef.ref('prod/user/' + userId + '/' + PATH_PROFILE);
  },
  getAppUserProfilePremiumRef(userId) {
    return window.firebaseDatabaseRef.ref('prod/user/' + userId + '/' + PATH_PROFILE + '/' + PATH_PREMIUM);
  },
  getAppUserProfileAddonsRef(userId) {
    return window.firebaseDatabaseRef.ref('prod/user/' + userId + '/' + PATH_PROFILE + '/' + PATH_ADD_ONS);
  },
  getAppUserActionLogRef(userId) {
    return window.firebaseDatabaseRef.ref('prod/user/' + userId + '/' + PATH_ACTION_LOG);
  },
  syncAppConfig(onSuccess, onError) {
    this.getAppConfigRef().get().then((snapshot) => {
      if (snapshot.exists()) {
        onSuccess(model.appConfig(snapshot.val()));
      } else {
        onError('no data');
      }
    }).catch((error) => {
      onError(error);
    });
  },
  syncAppAddonsConfig(onSuccess, onError) {
    this.getAppAddonsRef().get().then((snapshot) => {
      if (snapshot.exists()) {
        onSuccess(model.profileAddons(snapshot.val()));
      } else {
        onError('no data');
      }
    }).catch((error) => {
      onError(error);
    });
  },
  updateAppVersion(newVersion, onSuccess, onError) {
    const value = {};
    value[KEY_VERSION] = newVersion;
    this.getAppConfigRef().update(value)
      .then(() => {
        onSuccess();
      }).catch((error) => {
      onError(error);
    });
  },
  updateAppAddonsConfig(update, onSuccess, onError) {
    this.getAppAddonsRef().update(update)
      .then(() => {
        onSuccess(model.profileAddons(update));
      }).catch((error) => {
      onError(error);
    });
  },
  updateInAppNotification(description, link, onSuccess, onError) {
    const value = {};
    value[KEY_DESCRIPTION] = description;
    value[KEY_LINK_URL] = link;
    this.getAppConfigRef().update(value)
      .then(() => {
        onSuccess();
      }).catch((error) => {
      onError(error);
    });
  },
  syncUserProfile(userId, onSuccess, onError) {
    this.getAppUserProfileRef(userId).get().then((snapshot) => {
      if (snapshot.exists()) {
        const userProfile = model.appUserProfile(snapshot.val());
        this.syncUserAction(userId,
          (actions) => {
            onSuccess(userProfile, actions);
          },
          () => {
            onSuccess(userProfile, []);
          })

      } else {
        onError('no profile');
      }
    }).catch((error) => {
      onError(error);
    });
  },
  requestUserProfilePremium(userId, enable, onSuccess, onError) {
    this.getAppUserProfilePremiumRef(userId).set(enable).then(() => {
      onSuccess();
    }).catch((error) => {
      onError(error);
    });
  },
  updateUserProfileAddon(userId, addonConfig, onSuccess, onError) {
    //logger.d(LOG_TAG, 'updateUserProfileAddon', addonConfig);
    this.getAppUserProfileAddonsRef(userId).set(addonConfig).then(() => {
      onSuccess();
    }).catch((error) => {
      onError(error);
    });
  },
  requestActionLogout(userId, onSuccess, onError) {
    const value = {};
    value[KEY_ACTION] = ACTION_LOGOUT;
    value[KEY_TIMESTAMP] = parseInt(dateTimeHelper.currentTimeInSecond());
    this.getAppUserActionLogRef(userId).push(value).then(() => {
      this.syncUserAction(userId,
        (actions) => {
          onSuccess(actions);
        },
        () => {
          onSuccess([]);
        })

      }).catch((error) => {
      onError(error);
    });
  },
  syncUserAction(userId, onSuccess, onError) {
    logger.d(LOG_TAG, 'syncUserAction', userId);
    this.getAppUserActionLogRef(userId).limitToLast(2).once('value', (snapshot) => {
      const actions = [];
      snapshot.forEach((childSnapshot) => {
        //const key = childSnapshot.key;
        const action = model.appUserAction(childSnapshot.val());
        //logger.d(LOG_TAG, 'syncUserAction', key, action);
        actions.push(action);
      });
      onSuccess(actions);
    }).catch((error) => {
      onError(error);
    });
  },
  syncPremiumUsers(onSuccess, onError) {
    logger.d(LOG_TAG, 'syncPremiumUsers');
    this.getAppUser()
      .orderByChild('profile/premium')
      .equalTo(true)
      .once('value', (snapshot) => {
      const premiumUsers = [];
      snapshot.forEach((childSnapshot) => {
        const key = childSnapshot.key;
        premiumUsers.push(model.memberResponse({
          userUuid: key,
        }));
      });
        logger.d(LOG_TAG, 'syncPremiumUsers', premiumUsers.length);
      onSuccess(premiumUsers);
    }).catch((error) => {
      onError(error);
    });
  },
};
