import Vue from 'vue'
import VueRouter from 'vue-router'
import SignIn from '../views/page/sign_in.vue'
import AutoLogin from '../views/page/auto_login.vue'
import Dashboard from '../views/page/dashboard.vue'
import Certificate from '../views/page/certificate.vue'
import PlaceHolderPage from '../views/page/page_holder_tab.vue'
import UpdateResult from '../views/content/update_result.vue'
import UpdateEvent from '../views/content/update_event.vue'
import UpdatePyschSheet from '../views/content/update_pysch_sheet.vue'
import UpdateBanner from '../views/content/update_banner.vue'
import BroadcastMessage from '../views/content/broadcast_message.vue'
import Report from '../views/content/report.vue'
import ResetPassword from '../views/content/reset_password.vue'
import SuperUser from '../views/content/super_user.vue'
import PremiumUsers from '../views/content/premium_users.vue'
import SyncUserRecords from '../views/content/sync_user_records.vue'
// mysquad
import Join from '../views/page/join.vue'
import MyClub from '../views/content/my_club.vue'
import SetupClub from '../views/content/setup_club.vue'
import TrainingSets from '../views/content/training_sets.vue'
import ClubReports from '../views/content/club_reports.vue'
import TrainingSchedules from '../views/content/components/squad_schedules.vue'
import MySquads from '../views/content/my_squads.vue'

import PlaceHolder from '../views/content/place_holder.vue'
import logger from "@/util/logger"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    component: SignIn
  },
  {
    path: '/sign-in',
    name: '/sign_in',
    component: SignIn
  },
  {
    path: '/auto-login',
    name: '/auto_login',
    component: AutoLogin
  },
  {
    path: '/join',
    name: 'join',
    component: Join
  },
  {
    path: '/certificate',
    name: 'certificate',
    component: Certificate
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '/dashboard/update-result',
        name: UpdateResult.route,
        component: UpdateResult
      },
      {
        path: '/dashboard/update-event',
        name: UpdateEvent.route,
        component: UpdateEvent
      },
      {
        path: '/dashboard/update-pysch-sheet',
        name: UpdatePyschSheet.route,
        component: UpdatePyschSheet
      },
      {
        path: '/dashboard/update-banner',
        name: UpdateBanner.route,
        component: UpdateBanner
      },
      {
        path: '/dashboard/broadcast-message',
        name: BroadcastMessage.route,
        component: BroadcastMessage
      },
      {
        path: '/dashboard/report',
        name: Report.route,
        component: Report
      },
      {
        path: '/dashboard/reset-password',
        name: ResetPassword.route,
        component: ResetPassword
      },
      {
        path: '/dashboard/super-user',
        name: SuperUser.route,
        component: SuperUser
      },
      {
        path: '/dashboard/premium-users',
        name: PremiumUsers.route,
        component: PremiumUsers
      },
      {
        path: '/dashboard/sync-user-records',
        name: SyncUserRecords.route,
        component: SyncUserRecords
      },
      {
        path: '/dashboard/place-holder',
        name: PlaceHolder.route,
        component: PlaceHolder
      },
      // mysquads
      {
        path: '/dashboard/setup-club',
        name: SetupClub.route,
        component: SetupClub
      },
      {
        path: '/dashboard/my-club',
        name: MyClub.route,
        component: MyClub
      },
      {
        path: '/dashboard/training-sets',
        name: TrainingSets.route,
        component: TrainingSets
      },
      {
        path: '/dashboard/club-reports',
        name: ClubReports.route,
        component: ClubReports
      },
      {
        path: '/dashboard/training-schedules',
        name: TrainingSchedules.route,
        component: TrainingSchedules
      },
      {
        path: '/dashboard/my-squads',
        name: MySquads.route,
        component: MySquads
      },
    ]
  },
  {
    path: '/place-holder-tab',
    name: 'place_holder_tab',
    component: PlaceHolderPage,
  },
  {
    path: '/help',
    name: 'help',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/page/help.vue')
  },
  {
    path: '/account',
    name: 'account',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/page/account.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(m => m.meta.auth)) {
    const authenticated = window.store.state.auth.isAuthenticated();
    logger.d('main', 'beforeEach', to.name, authenticated);
    if (!authenticated) {
      next({
        path: '/sign_in',
      })
    } else {
      next()
    }
  } else {
    logger.d('main', 'beforeEach', to.name);
    if (null==to.name || to.name.length == 0) {
      next({
        path: '/',
      })
    } else {
      next()
    }

  }
})
export default router
